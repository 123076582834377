import Button  from '@mui/material/Button'

const GlobalButton = (props) => {

    const {
        hover,
        shadow,
        className,
        children,
        sx, 
        startIcon,
        endIcon,
        fullWidth, 
        default_classes = "justify-center text-align-center align-items-center",
        ...rest
    } = props

    const defualt_sx = {
        ":hover":{transform: hover ? 'translateY(-3px)' : 'none'},
        transition: '0.2s ease',
        boxShadow: shadow ? 'rgba(0, 0, 0, 0.24) 0px 3px 8px' : 'none',
    }

    return (
            <Button 
                className={`${default_classes} ${className}`}
                {...rest}
                sx={{
                    ...defualt_sx,
                    ...sx,
                }}
                startIcon={startIcon}
                endIcon={endIcon}
                fullWidth={fullWidth}
            >
            {children}
        </Button>
    )
}

export default GlobalButton